import * as React from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Flickity from "react-flickity-component"
import "../css/flickity.css"

import Layout from "../components/layout"
import Seo from "../components/seo"

const IndexPage = props => {
  const workList = props.data.work
  const quoteList = props.data.quotes

  const flickityOptions = {
    autoPlay: 15000,
    adaptiveHeight: true,
    contain: true,
    prevNextButtons: true,
    pageDots: false,
    wrapAround: true,
  }

  return (
    <Layout>
      <Seo title="Work" />
      <div className="lg:flex">
        <div className="lg:w-2/3 lg:pb-16">
          <ul className="work-thumbnails ml-0">
            {workList.edges.map(({ node }, i) => (
              <li className="work-thumbnail" key={node.fields.uri}>
                <Link to={node.fields.uri} className="link-item">
                  <GatsbyImage
                    image={getImage(node.frontmatter.thumbnail)}
                    className={`block w-full opacity-60 hover:opacity-100 ease-in-out duration-300 mb-1`}
                    layout="fixed"
                    alt={node.frontmatter.title}
                  />
                  <span className="block">{node.frontmatter.client}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="py-8 lg:py-0 lg:w-1/3 px-8 flex items-center overflow-hidden">
          <Flickity className="w-full -mx-1" options={flickityOptions} static>
            {quoteList.edges.map(({ node }, i) => (
              <div className="slide w-full lg:h-full lg:flex lg:items-center">
                <blockquote
                  key={i}
                  className="testimonial block text-sm w-full px-1"
                >
                  <span
                    dangerouslySetInnerHTML={{ __html: node.html }}
                    className="italic"
                  ></span>
                  <cite className="block text-xs not-italic mt-3">
                    {node.frontmatter.title}, {node.frontmatter.role}
                  </cite>
                </blockquote>
              </div>
            ))}
          </Flickity>
        </div>
      </div>
      <div className="sm:flex mt-4">
        <h2 className="uppercase tracking-widest mr-4 font-sans mb-3 sm:mb-0">
          Awards
        </h2>
        <p>
          Clio - Hall of Fame, Best of Show, Gold, Silver, Bronze; Cannes Lions
          - Gold and Silver;
          <br />
          Grand Effie; One Show Pencils; Radio Mercury Awards - Best of Show
        </p>
      </div>
    </Layout>
  )
}

export const workQuery = graphql`
  query Query {
    work: allMarkdownRemark(
      sort: { order: ASC, fields: fields___order }
      filter: { fields: { collection: { in: ["work"] } } }
      limit: 15
    ) {
      edges {
        node {
          frontmatter {
            title
            client
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  width: 160
                  height: 106
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
          fields {
            slug
            uri
            collection
            order
          }
        }
      }
    }

    quotes: allMarkdownRemark(
      filter: { fields: { collection: { in: ["quotes"] } } }
      sort: { fields: fields___order }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            role
          }
        }
      }
    }
  }
`

export default IndexPage
